<template>
  <v-main style="padding-top: 50px; background: url(assets/img/fundo_login.jpg) center center">
    <v-row dense class="pa-4">
      <v-col cols="12">
        <v-card class="d-flex align-center">
          <v-card-text class="my-3" style="font-size: 20px;">
            Olá <b>{{ usuario.Nome }}</b>, bem vindo ao seu ESCRITÓRIO!
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
export default {
  data () {
    return {};
  },
  computed: {
    usuario () {
      return this.$store.state.auth.userData;
    },
  },
};
</script>
